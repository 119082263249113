import React from 'react'
// import * as _ from 'lodash';

import Layout from '../components/layout'
import Menu from '../components/menu-component'

import './index.css';

const IndexPage = (props) => {
  return (
    <Layout>
      <h1>Projects</h1>
      <Menu />
      <div class="pure-g">
          <div class="l-box pure-u-1 pure-u-lg-1-3">
              <h3 class="content-subhead">
                This page will be a list of external links to projects, as well as any available code for those projects. You will also find a description of my role on the project and the technologies used for it.
              </h3>
          </div>
      </div>
    </Layout>
  )
}

export default IndexPage